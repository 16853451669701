export const watch = {
  hoaId() {
    this.refresh()
  },
  id() {
    this.refresh()
  },
  physicalLocation() {
    this.physicalLocationSelected = true
  },
  virtualLocation() {
    this.virtualLocationSelected = true
  },
  phoneLocation() {
    this.phoneLocationSelected = true
  },
  cancelled(cancelled) {
    this.cancellationReasonOff = !cancelled
  }
}
