/*
	import { addBoardPackage } from '@/services/BoardMeetings/BoardPackage/Post';

  const { phonePackage } = await addBoardPackage ({
    json: {
			hoaID,
      
      phoneNumber,
      
      "meetingDate": "2022-09-20T06:45:23.271Z",
      "financialMonth": "2022-09-20T06:45:23.271Z",
      
      //
      //  "Executive"
      //  "Regular"
      //
      "type": "string",
      
      "agendaJson": "{}",
      
      ///////
      // optional
      //
      // "physicalMeetingID": 6,
      // "virtualMeetingID": 4,
      // "phoneMeetingID": 4,
    }
  });
*/

/*
  exception.fields {
    phoneNumber: [ '' ],    
  }
*/

import kms from '@/services/kms'
import { notifyError } from '@/services/notify'

export async function addBoardPackage({ json }) {
  let exception = ''

  try {
    const result = await kms.post(`/BoardMeetings/BoardPackage`, json)

    return {
      successful: true,
      message: 'The board meeting was added successfully.',
      exception: null,
      result
    }
  } catch (_exception) {
    exception = _exception
  }

  let message = 'The board meeting could not be added.'
  notifyError(message)

  return {
    successful: false,
    message,
    exception,
    result: null
  }
}
