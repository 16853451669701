import _get from 'lodash/get'

export function data() {
  const mode = _get(this, ['$route', 'query', 'mode'], '')

  return {
    loading: false,
    editMode: this.adderModal || mode === 'edit',
    meetingType: '',
    hideReportTimes: false,
    phoneLocationSelected: false,
    phoneLocation: '',
    virtualLocationSelected: false,
    virtualLocation: '',
    physicalLocationSelected: false,
    physicalLocation: '',
    financialMonth: '',
    cancellationReasonOff: true,
    cancelledReason: '',
    isCancelled: false,
    meetingTypes: ['Executive', 'Regular'],
    phoneLocations: [],
    virtualLocations: [],
    physicalLocations: [],
    point: new Date().toISOString(),
    styles: {
      meetingLabels: {
        display: 'flex',
        flexBasis: '25%',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingRight: '8px'
      }
    }
  }
}
