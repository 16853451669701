import { listAllMeetingLocations } from '@/services/BoardMeetings/_procedures/listAllMeetingLocations'
import { addBoardPackage } from '@/services/BoardMeetings/BoardPackage/Post'
import { getBoardPackage } from '@/services/BoardMeetings/BoardPackage/Get'
import { modifyBoardPackage } from '@/services/BoardMeetings/BoardPackage/Put'

import _get from 'lodash/get'
import { notifyMessage, notifyError } from '@/services/notify'

import moment from 'moment'

export const methods = {
  async resetForm() {},

  moment,

  openEditMode() {
    this.editMode = !this.editMode
  },

  async refresh() {
    this.loading = true

    const hoaID = this.hoaId
    if (typeof hoaID !== 'number' || hoaID <= 0) {
      console.log('hoaId', hoaID)
      this.loading = false
      return
    }

    const { physicalMeetings, phoneMeetings, virtualMeetings } = await listAllMeetingLocations({
      hoaID
    })

    this.physicalLocations = physicalMeetings
    this.phoneLocations = phoneMeetings
    this.virtualLocations = virtualMeetings

    if (this.mode === 'create') {
      this.loading = false
      return
    }

    //////////////////////////////////////////////////

    const id = this.id

    let boardPackage = null
    await getBoardPackage({
      boardPackageID: id
    }).then(({ successful, message, boardPackage: boardPackage_ }) => {
      if (!successful) {
        throw new Error(message)
      }

      boardPackage = boardPackage_
    })

    const phoneMeeting = _get(boardPackage, 'phoneMeeting', '')
    const phoneMeetingID = _get(phoneMeeting, 'phoneMeetingID', '')
    if (typeof phoneMeetingID === 'number' && phoneMeetingID >= 1) {
      this.phoneLocation = phoneMeeting
    }

    const physicalMeeting = _get(boardPackage, 'physicalMeeting', '')
    const physicalMeetingID = _get(physicalMeeting, 'physicalMeetingID', '')
    if (typeof physicalMeetingID === 'number' && physicalMeetingID >= 1) {
      this.physicalLocation = physicalMeeting
    }

    const virtualMeeting = _get(boardPackage, 'virtualMeeting', '')
    const virtualMeetingID = _get(virtualMeeting, 'virtualMeetingID', '')
    if (typeof virtualMeetingID === 'number' && virtualMeetingID >= 1) {
      this.virtualLocation = virtualMeeting
    }

    this.hideReportTimes = _get(boardPackage, 'hideAgendaTimes', false)

    this.point = _get(boardPackage, 'meetingDate', '')

    const financialMonth = _get(boardPackage, 'financialMonth', '')

    if (this.editMode === true) {
      this.$refs.financialMonth.setValue()
    }

    this.financialMonth = financialMonth

    this.meetingType = _get(boardPackage, 'type', '')

    this.isCancelled = _get(boardPackage, 'isCancelled', '')
    this.cancelledReason = _get(boardPackage, 'cancelledReason', '')

    this.boardPackage = boardPackage

    this.loading = false
  },
  getVirtualLocations() {},

  onUpdate() {},
  async update() {
    console.debug('in update...')
    if (
      (this.isCancelled === true && this.cancelledReason === '') ||
      this.cancelledReason === null
    ) {
      notifyError('Please enter a cancellation reason for this meeting.')
      return
    } else {
      console.debug('not cancelled...' + this.cancelledReason)
    }

    this.loading = true

    const hoaID = this.hoaId

    const {
      meetingDate,
      financialMonth,
      meetingType,
      meetingIds,
      isCancelled,
      cancelledReason
    } = this.getData()

    const meetingDate_ = _get(meetingDate, 'value', '')
    const financialMonth_ = _get(financialMonth, 'utcFormat', '')

    await modifyBoardPackage({
      json: {
        hoaID,

        boardPackageID: this.id,

        meetingDate: meetingDate_,
        financialMonth: financialMonth_,

        //
        //  "Executive"
        //  "Regular"
        //
        type: meetingType,

        agendaJson: _get(this, ['boardPackage', 'agendaJson'], {}),

        isCancelled,
        cancelledReason,
        hideAgendaTimes: this.hideReportTimes,
        ///////
        // optional
        //
        // "physicalMeetingID": 6,
        // "virtualMeetingID": 4,
        // "phoneMeetingID": 4,
        ...meetingIds
      }
    })

    await this.refresh()

    this.loading = false

    notifyMessage('This meeting has been successfully updated.')
  },

  async add() {
    const hoaID = this.hoaId

    if (this.isCancelled && this.cancelledReason === '') {
      notifyError('Please enter a cancellation reason for this cancellation.')
      return
    }

    const {
      meetingDate,
      financialMonth,
      meetingType,
      meetingIds,
      isCancelled,
      cancelledReason
    } = this.getData()

    const meetingDate_ = _get(meetingDate, 'value', '')
    const financialMonth_ = _get(financialMonth, 'utcFormat', '')

    const json = {
      hoaID,

      meetingDate: meetingDate_,
      financialMonth: financialMonth_,

      //
      //  "Executive"
      //  "Regular"
      //
      type: meetingType,

      agendaJson: '{}',

      isCancelled,
      cancelledReason,
      hideAgendaTimes: this.hideReportTimes,
      ///////
      // optional
      //
      // "physicalMeetingID": 6,
      // "virtualMeetingID": 4,
      // "phoneMeetingID": 4,
      ...meetingIds
    }

    const { successful, message } = await addBoardPackage({
      json
    })
    if (successful) {
      notifyMessage(message)
    }

    this.close()
  },
  getData() {
    const {
      phoneLocationSelected,
      virtualLocationSelected,
      physicalLocationSelected,
      //
      phoneLocation,
      virtualLocation,
      physicalLocation
    } = this

    // const { day, month, year, allValid, utcFormat }
    const meetingDate = this.$refs.meetingDate.getValue()
    const financialMonth = this.$refs.financialMonth.getValue()
    this.financialMonth = financialMonth

    const meetingType = this.meetingType

    const locations = {}
    const meetingIds = {}
    if (phoneLocationSelected) {
      const phoneMeetingID = _get(phoneLocation, 'phoneMeetingID', null)

      if (typeof _get(phoneLocation, 'phoneMeetingID', null) === 'number') {
        locations.phoneLocation = phoneLocation
        meetingIds.phoneMeetingID = phoneMeetingID
      }
    }
    if (virtualLocationSelected) {
      const virtualMeetingID = _get(virtualLocation, 'virtualMeetingID', null)

      if (typeof _get(virtualLocation, 'virtualMeetingID', null) === 'number') {
        locations.virtualLocation = virtualLocation
        meetingIds.virtualMeetingID = virtualMeetingID
      }
    }
    if (physicalLocationSelected) {
      const physicalMeetingID = _get(physicalLocation, 'physicalMeetingID', null)

      if (typeof _get(physicalLocation, 'physicalMeetingID', null) === 'number') {
        locations.physicalLocation = physicalLocation
        meetingIds.physicalMeetingID = physicalMeetingID
      }
    }

    const isCancelled = this.isCancelled
    const cancelledReason = this.cancelledReason

    return {
      meetingDate,
      financialMonth,
      meetingType,
      locations,
      meetingIds,
      isCancelled,
      cancelledReason
    }
  }
}
