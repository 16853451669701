/*
  import { listAllMeetingLocations } from '@/services/BoardMeetings/_procedures/listAllMeetingLocations'
  
  const {
    physicalMeetings,
    phoneMeetings,
    virtualMeetings
  } = await listAllMeetingLocations ({
    hoaID
  })
*/

import { listPhoneMeetings } from '@/services/BoardMeetings/PhoneMeeting/List'
import { listPhysicalMeetings } from '@/services/BoardMeetings/PhysicalMeeting/List'
import { listVirtualMeetings } from '@/services/BoardMeetings/VirtualMeeting/List'

export async function listAllMeetingLocations({ hoaID }) {
  console.log({ hoaID })

  const results = await Promise.all([
    await listPhysicalMeetings({
      params: {
        hoaID
      }
    }),
    await listPhoneMeetings({
      params: {
        hoaID
      }
    }),
    await listVirtualMeetings({
      params: {
        hoaID
      }
    })
  ])

  /* eslint-disable no-empty */
  let physicalMeetings = []
  try {
    physicalMeetings = results[0].physicalMeetings
  } catch (x) {}

  let phoneMeetings = []
  try {
    phoneMeetings = results[1].phoneMeetings
  } catch (x) {}

  let virtualMeetings = []
  try {
    virtualMeetings = results[2].virtualMeetings
  } catch (x) {}

  return {
    physicalMeetings,
    phoneMeetings,
    virtualMeetings
  }
}
