<template>
  <div :style="{ padding: '1rem', position: 'relative' }">
    <div
      :style="{
        position: 'absolute',

        top: '10px',
        right: '10px'
      }"
    >
      <b-tooltip
        v-if="!adderModal"
        :label="editMode ? 'Finish editing without saving' : 'Edit Meeting details'"
        position="is-left"
      >
        <button
          edit-request-details
          :aria-label="editMode ? 'finish editing without saving' : 'edit request details'"
          :style="{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }"
          @click="openEditMode()"
        >
          <b-button class="button is-primary is-rounded">Edit</b-button>
        </button>
      </b-tooltip>
    </div>

    <b-message
      v-if="!editMode && isCancelled"
      :style="{
        width: '80%',
        marginBottom: '30px'
      }"
    >
      <p
        :style="{
          fontSize: '30px'
        }"
      >
        Cancelled
      </p>
      <p>{{ cancelledReason }}</p>
    </b-message>

    <b-field label="Meeting Date & Time">
      <DateTimePicker v-if="editMode" ref="meetingDate" :point="point" />
      <div v-if="!editMode">{{ moment(point).format('LLL') }}</div>
    </b-field>

    <br />

    <b-field label="Financial Month">
      <DatePicker
        v-if="editMode"
        ref="financialMonth"
        ariaLabel="Financial Month"
        ariaPrefix="from"
        :fields="{
          year: true,
          month: true,
          dayOfMonth: false,
          menu: false
        }"
        :style="{ paddingRight: '10px' }"
        :onUpdate="onUpdate"
      />
      <p v-if="!editMode">{{ moment(financialMonth).format('MMMM YYYY') }}</p>
    </b-field>

    <br />

    <b-field label="Meeting Type">
      <b-autocomplete
        v-if="editMode"
        v-model="meetingType"
        :open-on-focus="true"
        :data="meetingTypes"
        @select="option => (selected = option)"
        :clearable="true"
      />

      <p v-if="!editMode">{{ meetingType }}</p>
    </b-field>

    <br />

    <b-checkbox v-model="hideReportTimes" class="pb-6">
      Hide report times
    </b-checkbox>

    <br />

    <div :style="{ display: 'flex', alignItems: 'middle' }">
      <b-field label="Meeting Location" />
      <b-tooltip
        v-if="editMode"
        label='Locations can be added on the "Meeting Locations" page.'
        multilined
        :style="{ marginTop: '-3px', marginLeft: '5px', cursor: 'pointer' }"
      >
        <InfoPlain />
      </b-tooltip>
      <p></p>
    </div>

    <div>
      <div :style="{ display: 'flex', height: '40px' }" v-if="editMode">
        <div :style="styles.meetingLabels">
          <b-checkbox v-model="phoneLocationSelected">Phone Meeting</b-checkbox>
        </div>

        <div :style="{ flexGrow: 1 }">
          <b-select
            :style="{
              opacity: phoneLocationSelected ? 1 : 0,
              visibility: phoneLocationSelected ? 'visible' : 'hidden',
              transition: '.5s all'
            }"
            v-model="phoneLocation"
          >
            <option v-for="option in phoneLocations" :value="option" :key="option.phoneMeetingID">
              {{ option.phoneNumber }}, access code: {{ option.accessCode }}
            </option>
          </b-select>
        </div>
      </div>

      <div
        :style="{ display: 'flex', justifyContent: 'space-between' }"
        v-if="!editMode && phoneLocationSelected"
      >
        <div :style="{}">Phone Meeting</div>
        <div>{{ phoneLocation.phoneNumber }}, access code: {{ phoneLocation.accessCode }}</div>
      </div>
    </div>

    <div>
      <div :style="{ display: 'flex', marginTop: '8px' }" v-if="editMode">
        <div :style="styles.meetingLabels">
          <b-checkbox v-model="physicalLocationSelected">Physical Location</b-checkbox>
        </div>
        <div :style="{ flexGrow: 1, height: '40px' }">
          <b-select
            :style="{
              opacity: physicalLocationSelected ? 1 : 0,
              visibility: physicalLocationSelected ? 'visible' : 'hidden',
              transition: '.5s all'
            }"
            v-model="physicalLocation"
          >
            <option
              v-for="option in physicalLocations"
              :value="option"
              :key="option.physicalMeetingID"
            >
              {{ option.locationName }}
            </option>
          </b-select>
        </div>
      </div>

      <div
        :style="{ display: 'flex', justifyContent: 'space-between' }"
        v-if="!editMode && physicalLocationSelected"
      >
        <div :style="{}">Physical Meeting</div>
        <div>{{ physicalLocation.locationName }}</div>
      </div>
    </div>

    <div>
      <div v-if="editMode" :style="{ display: 'flex', marginTop: '8px', height: '40px' }">
        <div :style="styles.meetingLabels">
          <b-checkbox v-model="virtualLocationSelected">Virtual Meeting</b-checkbox>
        </div>
        <div :style="{ flexGrow: 1 }">
          <b-select
            :style="{
              opacity: virtualLocationSelected ? 1 : 0,
              visibility: virtualLocationSelected ? 'visible' : 'hidden',
              transition: '.5s all'
            }"
            v-model="virtualLocation"
          >
            <option
              v-for="option in virtualLocations"
              :value="option"
              :key="option.virtualMeetingID"
            >
              {{ option.meetingLink }}
            </option>
          </b-select>
        </div>
      </div>

      <div
        v-if="!editMode && virtualLocationSelected"
        :style="{ display: 'flex', justifyContent: 'space-between' }"
      >
        <div :style="{}">Virtual Meeting</div>
        <div>{{ virtualLocation.meetingLink }}</div>
      </div>
    </div>

    <br />
    <b-checkbox v-if="editMode && !adderModal" v-model="isCancelled">Cancelled</b-checkbox>

    <div :style="{ padding: '10px 0' }" />

    <b-field
      label="Cancellation Reason"
      v-if="editMode && !adderModal"
      :style="{
        opacity: isCancelled ? 1 : 0,
        visibility: isCancelled ? 'visible' : 'hidden',
        transition: '.5s all'
      }"
    >
      <b-input type="textarea" v-model="cancelledReason"></b-input>
    </b-field>

    <div :style="{ padding: '10px 0' }" />

    <div v-if="showButtons && editMode" :style="{ padding: '50px 0' }">
      <b-button @click="update()" type="is-primary" :style="{ float: 'right' }">Save</b-button>
    </div>

    <b-loading :is-full-page="false" v-model="loading" :can-cancel="false"></b-loading>
  </div>
</template>

<script>
import DatePicker from '@/components/inputs/DatePicker'
import DateTimePicker from '@/components/inputs/DateTimePicker'
import InfoPlain from '@/components/icons/InfoPlain'
import { watch } from './parts/watch'
import { methods } from './parts/methods'
import { data } from './parts/data'
import { computed } from './parts/computed'

export default {
  components: {
    DatePicker,
    DateTimePicker,
    InfoPlain
  },
  props: {
    adderModal: {
      default: false,
      type: Boolean
    },
    showButtons: {
      default: false,
      type: Boolean
    },
    id: {
      default: false,
      type: [Boolean, Number]
    },
    close: {
      default() {},
      type: Function
    }
  },
  data,
  mounted() {
    this.refresh()
  },
  watch,
  methods,
  computed
}
</script>
