import { mapState } from 'vuex'

export const computed = {
  ...mapState({
    hoaId: state => state.user.selectedHoaId,
    breakpoint: state => state.win.breakpoint
  }),

  mode() {
    if (typeof this.id === 'number') {
      return 'update'
    }

    return 'create'
  }
}
