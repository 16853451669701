var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({ padding: '1rem', position: 'relative' })},[_c('div',{style:({
      position: 'absolute',

      top: '10px',
      right: '10px'
    })},[(!_vm.adderModal)?_c('b-tooltip',{attrs:{"label":_vm.editMode ? 'Finish editing without saving' : 'Edit Meeting details',"position":"is-left"}},[_c('button',{style:({
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }),attrs:{"edit-request-details":"","aria-label":_vm.editMode ? 'finish editing without saving' : 'edit request details'},on:{"click":function($event){return _vm.openEditMode()}}},[_c('b-button',{staticClass:"button is-primary is-rounded"},[_vm._v("Edit")])],1)]):_vm._e()],1),(!_vm.editMode && _vm.isCancelled)?_c('b-message',{style:({
      width: '80%',
      marginBottom: '30px'
    })},[_c('p',{style:({
        fontSize: '30px'
      })},[_vm._v(" Cancelled ")]),_c('p',[_vm._v(_vm._s(_vm.cancelledReason))])]):_vm._e(),_c('b-field',{attrs:{"label":"Meeting Date & Time"}},[(_vm.editMode)?_c('DateTimePicker',{ref:"meetingDate",attrs:{"point":_vm.point}}):_vm._e(),(!_vm.editMode)?_c('div',[_vm._v(_vm._s(_vm.moment(_vm.point).format('LLL')))]):_vm._e()],1),_c('br'),_c('b-field',{attrs:{"label":"Financial Month"}},[(_vm.editMode)?_c('DatePicker',{ref:"financialMonth",style:({ paddingRight: '10px' }),attrs:{"ariaLabel":"Financial Month","ariaPrefix":"from","fields":{
        year: true,
        month: true,
        dayOfMonth: false,
        menu: false
      },"onUpdate":_vm.onUpdate}}):_vm._e(),(!_vm.editMode)?_c('p',[_vm._v(_vm._s(_vm.moment(_vm.financialMonth).format('MMMM YYYY')))]):_vm._e()],1),_c('br'),_c('b-field',{attrs:{"label":"Meeting Type"}},[(_vm.editMode)?_c('b-autocomplete',{attrs:{"open-on-focus":true,"data":_vm.meetingTypes,"clearable":true},on:{"select":function (option) { return (_vm.selected = option); }},model:{value:(_vm.meetingType),callback:function ($$v) {_vm.meetingType=$$v},expression:"meetingType"}}):_vm._e(),(!_vm.editMode)?_c('p',[_vm._v(_vm._s(_vm.meetingType))]):_vm._e()],1),_c('br'),_c('b-checkbox',{staticClass:"pb-6",model:{value:(_vm.hideReportTimes),callback:function ($$v) {_vm.hideReportTimes=$$v},expression:"hideReportTimes"}},[_vm._v(" Hide report times ")]),_c('br'),_c('div',{style:({ display: 'flex', alignItems: 'middle' })},[_c('b-field',{attrs:{"label":"Meeting Location"}}),(_vm.editMode)?_c('b-tooltip',{style:({ marginTop: '-3px', marginLeft: '5px', cursor: 'pointer' }),attrs:{"label":"Locations can be added on the \"Meeting Locations\" page.","multilined":""}},[_c('InfoPlain')],1):_vm._e(),_c('p')],1),_c('div',[(_vm.editMode)?_c('div',{style:({ display: 'flex', height: '40px' })},[_c('div',{style:(_vm.styles.meetingLabels)},[_c('b-checkbox',{model:{value:(_vm.phoneLocationSelected),callback:function ($$v) {_vm.phoneLocationSelected=$$v},expression:"phoneLocationSelected"}},[_vm._v("Phone Meeting")])],1),_c('div',{style:({ flexGrow: 1 })},[_c('b-select',{style:({
            opacity: _vm.phoneLocationSelected ? 1 : 0,
            visibility: _vm.phoneLocationSelected ? 'visible' : 'hidden',
            transition: '.5s all'
          }),model:{value:(_vm.phoneLocation),callback:function ($$v) {_vm.phoneLocation=$$v},expression:"phoneLocation"}},_vm._l((_vm.phoneLocations),function(option){return _c('option',{key:option.phoneMeetingID,domProps:{"value":option}},[_vm._v(" "+_vm._s(option.phoneNumber)+", access code: "+_vm._s(option.accessCode)+" ")])}),0)],1)]):_vm._e(),(!_vm.editMode && _vm.phoneLocationSelected)?_c('div',{style:({ display: 'flex', justifyContent: 'space-between' })},[_c('div',{style:({})},[_vm._v("Phone Meeting")]),_c('div',[_vm._v(_vm._s(_vm.phoneLocation.phoneNumber)+", access code: "+_vm._s(_vm.phoneLocation.accessCode))])]):_vm._e()]),_c('div',[(_vm.editMode)?_c('div',{style:({ display: 'flex', marginTop: '8px' })},[_c('div',{style:(_vm.styles.meetingLabels)},[_c('b-checkbox',{model:{value:(_vm.physicalLocationSelected),callback:function ($$v) {_vm.physicalLocationSelected=$$v},expression:"physicalLocationSelected"}},[_vm._v("Physical Location")])],1),_c('div',{style:({ flexGrow: 1, height: '40px' })},[_c('b-select',{style:({
            opacity: _vm.physicalLocationSelected ? 1 : 0,
            visibility: _vm.physicalLocationSelected ? 'visible' : 'hidden',
            transition: '.5s all'
          }),model:{value:(_vm.physicalLocation),callback:function ($$v) {_vm.physicalLocation=$$v},expression:"physicalLocation"}},_vm._l((_vm.physicalLocations),function(option){return _c('option',{key:option.physicalMeetingID,domProps:{"value":option}},[_vm._v(" "+_vm._s(option.locationName)+" ")])}),0)],1)]):_vm._e(),(!_vm.editMode && _vm.physicalLocationSelected)?_c('div',{style:({ display: 'flex', justifyContent: 'space-between' })},[_c('div',{style:({})},[_vm._v("Physical Meeting")]),_c('div',[_vm._v(_vm._s(_vm.physicalLocation.locationName))])]):_vm._e()]),_c('div',[(_vm.editMode)?_c('div',{style:({ display: 'flex', marginTop: '8px', height: '40px' })},[_c('div',{style:(_vm.styles.meetingLabels)},[_c('b-checkbox',{model:{value:(_vm.virtualLocationSelected),callback:function ($$v) {_vm.virtualLocationSelected=$$v},expression:"virtualLocationSelected"}},[_vm._v("Virtual Meeting")])],1),_c('div',{style:({ flexGrow: 1 })},[_c('b-select',{style:({
            opacity: _vm.virtualLocationSelected ? 1 : 0,
            visibility: _vm.virtualLocationSelected ? 'visible' : 'hidden',
            transition: '.5s all'
          }),model:{value:(_vm.virtualLocation),callback:function ($$v) {_vm.virtualLocation=$$v},expression:"virtualLocation"}},_vm._l((_vm.virtualLocations),function(option){return _c('option',{key:option.virtualMeetingID,domProps:{"value":option}},[_vm._v(" "+_vm._s(option.meetingLink)+" ")])}),0)],1)]):_vm._e(),(!_vm.editMode && _vm.virtualLocationSelected)?_c('div',{style:({ display: 'flex', justifyContent: 'space-between' })},[_c('div',{style:({})},[_vm._v("Virtual Meeting")]),_c('div',[_vm._v(_vm._s(_vm.virtualLocation.meetingLink))])]):_vm._e()]),_c('br'),(_vm.editMode && !_vm.adderModal)?_c('b-checkbox',{model:{value:(_vm.isCancelled),callback:function ($$v) {_vm.isCancelled=$$v},expression:"isCancelled"}},[_vm._v("Cancelled")]):_vm._e(),_c('div',{style:({ padding: '10px 0' })}),(_vm.editMode && !_vm.adderModal)?_c('b-field',{style:({
      opacity: _vm.isCancelled ? 1 : 0,
      visibility: _vm.isCancelled ? 'visible' : 'hidden',
      transition: '.5s all'
    }),attrs:{"label":"Cancellation Reason"}},[_c('b-input',{attrs:{"type":"textarea"},model:{value:(_vm.cancelledReason),callback:function ($$v) {_vm.cancelledReason=$$v},expression:"cancelledReason"}})],1):_vm._e(),_c('div',{style:({ padding: '10px 0' })}),(_vm.showButtons && _vm.editMode)?_c('div',{style:({ padding: '50px 0' })},[_c('b-button',{style:({ float: 'right' }),attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.update()}}},[_vm._v("Save")])],1):_vm._e(),_c('b-loading',{attrs:{"is-full-page":false,"can-cancel":false},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }